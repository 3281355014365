@import "../../styles/media-query";

.root {
    background-color: var(--theme-palette-background-accent);
    margin-top: calc(var(--theme-spacing) * 15);
}

.content {
    display: grid;
    width: 100%;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    padding-top: calc(var(--theme-spacing) * 4);
    padding-bottom: calc(var(--theme-spacing) * 4);

    @include medium {
        grid-template-columns: 1fr;
        justify-items: center;
    }
}

.title {
    margin-bottom: calc(var(--theme-spacing) * 2);
}

.entry {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &.profile {
        align-items: center;
    }

    &.description {
        @include medium {
            max-width: 100%;
        }
    }

    a {
        text-decoration: none;
        color: var(--theme-palette-primary-main);
        font-weight: bold;
    }

    @include medium {
        margin-bottom: calc(var(--theme-spacing) * 4);
    }
}

.text {
    text-align: center;
    max-width: 360px;
}

.profileName {
    margin-top: calc(var(--theme-spacing) * 1);
    font-weight: bold;
}
