@mixin medium() {
    @media screen and (max-width: 1280px) {
        @content
    }
}

@mixin small() {
    @media screen and (max-width: 680px) {
        @content
    }
}
