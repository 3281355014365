@import "../../styles/media-query";

.root {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: calc(var(--theme-spacing) * 2);
    padding: calc(var(--theme-spacing) * 6) 0;

    @include medium {
        grid-template-columns: repeat(2, 1fr);
    }

    @include small {
        grid-template-columns: repeat(1, 1fr);
    }
}

.entry {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    @include small {
        justify-content: center;
    }
}
