@import "../../../src/styles/media-query";

.root {
    display: grid;
    grid-template-areas: 'left right';
    grid-template-columns: 1fr 1fr;
    grid-gap: calc(var(--theme-spacing) * 2);

    @include small {
        grid-template-areas: 'left';
        grid-template-columns: 1fr;
        grid-row-gap: calc(var(--theme-spacing) * 2);
    }
}
