@import "../../styles/media-query";

.root {
    --container-edge-gutter: 32px;
    --container-container-width: 1440px;
}

.root.white {
    background-color: white;
}

.root.accent {
    background-color: transparent;
}

.root.padded {
    .content {
        max-width: calc(1440px - 52px - 52px);
        padding: 0 var(--container-edge-gutter);

        @include small {
            --container-edge-gutter: 12px;
        }
    }

    .wrapper {

    }
}

.content {
    max-width: var(--container-container-width);
    margin: 0 auto;
}
