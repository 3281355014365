.root {

}


.divider {
    margin: 0 calc(var(--theme-spacing));
}

.label {
    text-decoration: none;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.3);
}

.label.labelActive {
    color: #000;
}
