.root {
    display: flex;
    flex-direction: column;
    padding: 54px 0 0;
}

.rightWrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.title {
    font-weight: bold;
    cursor: pointer;
}

.pageTitle {
    margin-bottom: 54px;
}

.content {
    padding-bottom: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 1px var(--theme-palette-primary-main);
    margin-bottom: 54px;
}

.loginWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.loginLabel {
    margin-right: var(--theme-spacing);
}

.switcher {
    margin-right: calc(var(--theme-spacing) * 2);
}

.button {
    background-color: rgba(22, 127, 146, 0.1);
    color: rgba(0, 0, 0, 0.8);
    font-weight: bold;
}
