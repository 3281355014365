@import "../../styles/media-query";

.root {
    background-color: var(--theme-palette-primary-main);
}

.content {
    padding-top: calc(var(--theme-spacing) * 4);
    padding-bottom: calc(var(--theme-spacing) * 4);
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include small {
        flex-direction: column;
    }
}

.description {
    margin-top: calc(var(--theme-spacing) * 2);
}

.mailLink {
    color: var(--theme-palette-primary-contrasttext);
    text-decoration: none;
}

.left {
    max-width: 240px;
}

.title {
    font-weight: bold;

    @include small {
        font-size: 34px;
        margin-top: calc(var(--theme-spacing) * 5);
    }
}

.linkWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.logoWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.link {
    color: var(--theme-palette-common-white);
    text-decoration: underline;
    cursor: pointer;
    margin-bottom: calc(var(--theme-spacing) * 0.5);
}
