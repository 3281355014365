@import '../src/styles/media-query';

.section {
    margin-top: calc(var(--theme-spacing) * 15);

    &.colored {
        background-color: var(--theme-palette-background-accent);
    }
}

.headerDashboardCTA {
    @include small {
        display: none;
    }
}

.headerDashboardCTAMobile {
    display: none;
    @include small {
        display: block;
    }
}

.centeredColumn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.mapSectionContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 360px;
}

.mapSectionCTA, .mapSectionDescription {
    margin-top: calc(var(--theme-spacing) * 3)
}

.contactCTATitle {
    @include small {
        font-size: 34px;
    }
}
