@import "../../styles/media-query";

.introText {
    @include small {
        font-size: 20px;
    }
}

.introText + .introText {
    margin-top: calc(var(--theme-spacing) * 4)
}

.sectionLeft {
    max-width: 620px;
}

.splitSection {
    @include medium {
        display: flex;
        flex-direction: column;


        .sectionLeft {
            max-width: 100%;
            margin-bottom: calc(var(--theme-spacing) * 3);
        }
    }
}

.circularChartVisualLegend {
    max-width: 300px;
    text-align: center;
    margin-top: calc(var(--theme-spacing) * 2);
}

.circularChartVisual {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
