@import "../../styles/media-query";

.root {

}


.header {
    background-image: url('/header.png');
    background-size: cover;
    background-position: center;
    height: 278px;
    content: '';

    @include small {
        display: none;
    }
}

.entryTitle {
    margin-bottom: calc(var(--theme-spacing) * 2);
    font-weight: 200;

    @include small {
        font-size: 34px;
    }
}

.content {
    display: grid;
    grid-template-areas: 'item1 item2 item3';
    grid-template-columns: repeat(3, 1fr);
    margin-top: calc(var(--theme-spacing) * 3);

    @include medium {
        display: grid;
        grid-template-areas: 'item1';
        grid-template-columns: 1fr;
    }

    a {
        text-decoration: none;
        color: var(--theme-palette-primary-main);
        font-weight: bold;
    }
}

.content > * {
    padding: calc(var(--theme-spacing) * 4) calc(var(--theme-spacing) * 3);

    @include small {
        padding: calc(var(--theme-spacing) * 4) 0;
    }
}


.content > * + * {
    border-left: solid 1px var(--theme-palette-primary-main);

    @include medium {
        border-left: none;
        border-top: solid 1px var(--theme-palette-primary-main);
    }
}
